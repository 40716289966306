<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <MainForm
          :form="form"
          :error="error"
          :purpose="purpose"
          :mainSentenceShow="false"
          citizenTitleButton="Pilih Penduduk Meninggal"
        >
          <template v-slot:main>
            <label for="">Telah Meninggal Pada:</label>
            <b-input-group>
              <div class="row mb-2">
                <div class="col-md-6">
                  <v-dialog
                    ref="dialog"
                    v-model="modal2"
                    :return-value.sync="form.date_death"
                    persistent
                    width="350px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-input-group>
                        <b-input-group-prepend>
                          <button
                            class="btn btn-secondary"
                            type="button"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <i class="fas fa-calendar"></i>
                          </button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-trigger-modal-patient"
                          v-model="form.date_death"
                          placeholder="Tanggal (YYYY-MM-DD)"
                          readonly
                        >
                        </b-form-input>
                      </b-input-group>
                    </template>

                    <v-date-picker
                      v-if="modal2"
                      v-model="form.date_death"
                      locale="id"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(form.date_death)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>
                <div class="col">
                  <b-form-timepicker
                    v-model="form.death_time"
                    locale="id"
                    :hour12="false"
                    now-button
                    reset-button
                    placeholder="Jam"
                  ></b-form-timepicker>
                </div>
              </div>
            </b-input-group>
            <b-form-group
              id="input-group-place_death"
              label="Tempat meninggal:"
              label-for="input-place_death"
            >
              <b-form-input
                id="input-place_death"
                v-model="form.place_death"
                placeholder="Tempat meninggal"
              ></b-form-input>
              <small class="text-danger">{{ error.place_death }}</small>
            </b-form-group>
            <b-form-group
              id="input-group-cause"
              label="Sebab kematian:"
              label-for="input-cause"
            >
              <b-form-input
                id="input-cause"
                v-model="form.cause"
                placeholder="Sebab kematian"
              ></b-form-input>
              <small class="text-danger">{{ error.cause }}</small>
            </b-form-group>

            <fieldset
              style="border: 1px solid #dee2e6; padding: 8px"
              class="mb-3"
            >
              <legend
                style="
                  border: 1px solid #dee2e6;
                  padding: 9px;
                  margin-left: 13px;
                  font-size: initial;
                  width: initial;
                  background: white;
                "
              >
                Pelapor
              </legend>
              <div class="row mb-2">
                <div class="col">
                  <button
                    class="btn btn-info btn-block"
                    type="button"
                    @click="$bvModal.show('modal-citizen-2')"
                  >
                    Pilih Penduduk yang Melaporkan
                  </button>
                </div>
              </div>

              <b-form-group
                id="input-group-resource_name"
                label="Nama:"
                label-for="input-resource_name"
              >
                <b-form-input
                  id="input-resource_name"
                  v-model="formResource.resource_name"
                  placeholder="Nama"
                  readonly
                ></b-form-input>
                <small class="text-danger">{{ error.resource_name }}</small>
              </b-form-group>
              <b-form-group
                id="input-group-resource_id_card_number"
                label="NIK:"
                label-for="input-resource_id_card_number"
              >
                <b-form-input
                  id="input-resource_id_card_number"
                  v-model="formResource.resource_id_card_number"
                  placeholder="NIK"
                  readonly
                ></b-form-input>
                <small class="text-danger">{{
                  error.resource_id_card_number
                }}</small>
              </b-form-group>
              <b-form-group
                id="input-group-resource_birt_place_date"
                label="Tempat / Tanggal Lahir:"
                label-for="input-resource_birt_place_date"
              >
                <b-form-input
                  id="input-resource_birt_place_date"
                  v-model="formResource.resource_birt_place_date"
                  placeholder="Tempat / Tanggal Lahir"
                  readonly
                ></b-form-input>
                <small class="text-danger">{{
                  error.resource_birt_place_date
                }}</small>
              </b-form-group>
              <b-form-group
                id="input-group-resource_profession"
                label="Pekerjaan:"
                label-for="input-resource_profession"
              >
                <b-form-input
                  id="input-resource_profession"
                  v-model="formResource.resource_profession"
                  placeholder="Pekerjaan"
                  readonly
                ></b-form-input>
                <small class="text-danger">{{
                  error.resource_profession
                }}</small>
              </b-form-group>
              <b-form-group id="input-group-resource_address">
                <label for="input-resource_address">Alamat: </label>
                <b-form-textarea
                  id="input-resource_address"
                  v-model="formResource.resource_address"
                  placeholder="Alamat"
                  rows="4"
                  max-rows="8"
                  readonly
                ></b-form-textarea>
                <small class="text-danger">{{ error.resource_address }}</small>
              </b-form-group>
            </fieldset>
          </template>
        </MainForm>
        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/death-certificate')"
        >
          Batal
        </b-button>
      </b-form>
    </div>

    <ModalCitizen @chooseCitizen2="chooseCitizen2" id="modal-citizen-2" returnEmit="chooseCitizen2"/>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";
import ModalCitizen from "@/view/components/general/ModalCitizen.vue";

export default {
  props: {
    form: Object,
    formResource: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    MainForm,
    ModalCitizen,
  },

  data() {
    return {
      // Error
      error: {
        citizen_id: "",
        date_death: "",
        place_death: "",
        cause: "",
        resource_id: "",
        // 
        name: "",
        id_card_number: "",
        gender: "",
        birt_place: "",
        birt_date: "",
        title: "",
        number: "",
        hamlet_id: "",
        purpose: "",
      },
    //   formResource: {
    //     resource_name: "",
    //     resource_id_card_number: "",
    //     resource_birt_place: "",
    //     resource_birt_date: "",
    //     resource_profession: "",
    //     resource_address: "",
    //     resource_birt_place_date: "",
    //   },
      modal2: false,
    };
  },

  methods: {
    chooseCitizen2(value) {
      this.$bvModal.hide("modal-citizen-2");
      this.form.resource_id = value.id;
      this.formResource.resource_name = value.name;
      this.formResource.resource_id_card_number = value.id_card_number;
      this.formResource.resource_birt_place = value.birt_place;
      this.formResource.resource_birt_date = value.birt_date;
      this.formResource.resource_profession = value.profession;
      this.formResource.resource_address = value.address;
      this.formResource.resource_birt_place_date =
        value.birt_place + ", " + value.birt_date;

        console.log('formresource', this.formResource)
    },
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/death-certificate");
      }
    },
  },
  mounted(){
    // if(this.purpose == "edit"){
    //     this.form
    // }
  }
};
</script>

<style>
</style>